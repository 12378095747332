.react-autosuggest__container {
  /* padding-top: 2rem; */
}

.react-autosuggest__suggestions-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.react-autosuggest__suggestions-container {
  max-height: 400px;
  overflow-y: scroll;
}